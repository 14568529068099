import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import Select from 'react-select';
import Switch from "react-switch";
import { isBase64 } from 'is-base64';
import _ from "lodash";

import callApi from "../callApi";
import { data, earn_slice, special_earn_criteria, suggested_place } from "./data.js";

const creditCardSchema = _.cloneDeep(data);

const CreditCardAction = ({
    creditCard,
    handleModalClose,
    setButtonDisabled,
    updateCreditCardTable
}) => {
    const get_ddmmyyyy_date = (date_string) => {
        if (!date_string) {
            return "";
        }
        let date = new Date(date_string);

        let utc_date = date.getUTCDate();
        if (utc_date < 10) {
            utc_date = "0" + utc_date; 
        }

        let utc_month =  date.getUTCMonth();
        utc_month = utc_month + 1;
        if (utc_month < 10) {
            utc_month = "0" + utc_month;
        }

        let utc_year = date.getFullYear();

        return utc_year + "-" + utc_month + "-" + utc_date;
    };

    let data = _.cloneDeep(creditCard);
    if ('joining_benefit' in data &&
        'promotion' in data.joining_benefit &&
        'expiry_datetime' in data.joining_benefit.promotion &&
        data.joining_benefit.promotion.expiry_datetime) {
            data.joining_benefit.promotion.expiry_datetime = get_ddmmyyyy_date(
                data.joining_benefit.promotion.expiry_datetime
            );
        }
    if ('joining_benefit' in data &&
        'dobin_promotion' in data.joining_benefit &&
        'expiry_datetime' in data.joining_benefit.dobin_promotion &&
        data.joining_benefit.dobin_promotion.expiry_datetime) {
            data.joining_benefit.dobin_promotion.expiry_datetime = get_ddmmyyyy_date(
                data.joining_benefit.dobin_promotion.expiry_datetime
            );
        }

    const dataPreparations = (data, schema) => {
        for (let key in schema) {
            if(!(key in data) && (typeof(schema[key]) == "string")) {
                data[key] = schema[key];
            }
            else if(!(key in data) && (typeof(schema[key]) == "boolean")) {
                data[key] = schema[key];
            }
            else if(_.isArray(schema[key])) {
                if (typeof(schema[key][0]) == "object") {
                    if (!(key in data) || (data[key].length === 0)) {
                        data[key] = _.cloneDeep(schema[key]);
                    }
                    else {
                        data[key].forEach((element, index) => {
                            data[key][index] = dataPreparations(element, schema[key][0]);
                        });
                    }
                }
                else if (typeof(schema[key][0]) == "string") {
                    if (!(key in data) || (data[key].length === 0)) {
                        data[key] = schema[key];
                    }
                }
            }
            else if((typeof(schema[key]) == "object")) {
                if (!(key in data)) {
                    data[key] = _.cloneDeep(schema[key]);
                }
                else {
                    data[key] = dataPreparations(data[key], schema[key]);
                }
            }
        }
        return data;
    }
    data = dataPreparations(data, creditCardSchema);

    const [providerIds, setProviderIds] = useState([]);
    const [categories, setCategories] = useState([]);
    const [partnerNames, setPartnerNames] = useState([]);

    const networks = [
        { value: 'Visa', label: 'Visa' },
        { value: 'Amex', label: 'Amex' },
        { value: 'MasterCard', label: 'MasterCard' },
        { value: 'UnionPay', label: 'UnionPay' },
        { value: 'OCBC', label: 'OCBC' }
    ];

    const ownership_types = [
        { value: 'Proprietary', label: 'Proprietary' },
        { value: 'Cobranded', label: 'Cobranded' }
    ];

    const primary_types = [
        { value: 'credit_card', label: 'Credit Card' },
        { value: 'charge', label: 'Charge' }
    ];

    const secondary_types = [
        { value: 'miles', label: 'Miles' },
        { value: 'cashback', label: 'Cashback' },
        { value: 'rewards', label: 'Rewards' },
        { value: 'charge', label: 'Charge' }
    ];

    const reward_types = [
        { value: 'Miles', label: 'Miles' },
        { value: 'Cashback', label: 'Cashback' },
        { value: 'Rewards', label: 'Rewards' },
        { value: 'Rebate', label: 'Rebate' }
    ];

    const base_earn_types = [
        { value: 'Miles', label: 'Miles' },
        { value: 'Cashback', label: 'Cashback' },
        { value: 'Reward pts.', label: 'Reward pts.' },
        { value: 'Rebate', label: 'Rebate' },
        { value: 'Interest', label: 'Interest' },
        { value: 'Partner', label: 'Partner' },
        { value: 'Partner miles', label: 'Partner miles' }
    ];

    const capping_periods = [
        { value: 'Monthly', label: 'Monthly' },
        { value: 'Quaterly', label: 'Quaterly' },
        { value: 'Annual', label: 'Annual' }
    ];

    const getProviderIds = () => {
        let url = `${process.env.REACT_APP_API_URL}/v1/providers/providers`;
        callApi(url)
            .then((json) => {
                if (json.data && json.data.providers) {
                    let allProviderIds = [];
                    for (let provider in json.data.providers) {
                        if (json.data.providers[provider].visible) {
                            allProviderIds.push({
                                value: provider,
                                label: provider
                            });
                        }
                    }
                    setProviderIds(allProviderIds);
                }
            })
            .catch((err) => err);
    };

    const getPartnerNames = () => {
        let url = `${process.env.REACT_APP_API_URL}/v1/providers/partners`;
        callApi(url)
            .then((json) => {
                if (json.data && json.data.partners) {
                    let allPartnerNames = [];
                    for (let partner in json.data.partners) {
                        allPartnerNames.push({
                            value: json.data.partners[partner].name,
                            label: json.data.partners[partner].name
                        });
                    }
                    setPartnerNames(allPartnerNames);
                }
            })
            .catch((err) => err);
    };

    useEffect(() => {
        if (providerIds.length === 0) {
            getProviderIds();
        }
    }, [providerIds]);

    useEffect(() => {
        if (partnerNames.length === 0) {
            getPartnerNames();
        }
    }, [partnerNames]);

    const getCategories = () => {
        let url = `${process.env.REACT_APP_API_URL}/v1/merchants/categories`;
        callApi(url)
            .then((json) => {
                if (json.data && json.data.categories) {
                    let category_ids = Object.keys(json.data.categories);
                    let categories = [];
                    for (let category_id of category_ids) {
                        categories.push({
                            value: category_id,
                            label: category_id
                        });
                    }
                    setCategories(categories);
                }
            })
            .catch((err) => err);
    };

    useEffect(() => {
        if (categories.length === 0) {
            getCategories();
        }
    }, [categories]);

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleIcon = async (e, setFieldValue, fieldName) => {
        const file = e.target.files[0];
        const base64 = await convertToBase64(file);
        setFieldValue(fieldName, base64);
    };

    const credit_card_number_fields = [
        "base_earn.rate", "base_earn.spend_block", "base_earn.capping.amount", 
        "base_earn.minimum_spend.amount", 
        "special_earn.capping.amount", "special_earn.minimum_spend.amount",
        "annual_fee.amount", "supplementary_card_fee.amount", "purchase_rate.percent",
        "cash_rate.percent", "cash_fee_rate.percent", "forex_fcy_fee", 
        "forex_dcc_fee", "overlimit_fee", "repayment_rate.percent", "late_payment_fee",
        "eligibility.citizens.minimum_income.amount", "eligibility.foreigners.minimum_income.amount",
        "featured_position"
        // special earn criterias capping, minimum amount, maximum amount, 
        // rate, spend_block and 
        // special earn earn slices minimum and maximum fields managed separately
    ];

    const credit_card_number_field_names = [
        "rate", "spend_block", "amount", "percent", "minimum", "maximum",
        "forex_dcc_fee", "forex_fcy_fee", "overlimit_fee", "late_payment_fee",
        "featured_position"
    ];

    const dataTransformations = (data) => {
        let result = {};
        for (let key in data) {
            if (credit_card_number_field_names.includes(key) &&
                data[key] !== "") {
                    result[key] = parseFloat(data[key]);
            }
            else if(typeof(data[key]) == "string" && data[key] !== "") {
                result[key] = data[key];
            }
            else if(_.isArray(data[key]) && data[key].length > 0) {
                let items = [];
                for (let value of data[key]) {
                    if (typeof(value) == "string" && value !== "") {
                        items.push(value);
                    }
                    else if (typeof(value) == "object") {
                        let object_result = dataTransformations(value);
                        if (!_.isEmpty(object_result)) {
                            items.push(object_result);
                        }
                    }
                }
                if(items.length > 0) {
                    result[key] = items;
                }
            }
            else if(typeof(data[key]) == "object") {
                let object_result = dataTransformations(data[key]);
                if (!_.isEmpty(object_result)) {
                    result[key] = object_result;
                }
            }
            else if(typeof(data[key]) == "boolean") {
                result[key] = data[key];
            }
        }
        return result;
    };

    const updateCreditCard = (credit_card, setSubmitting) => {
        let url = `${process.env.REACT_APP_API_URL}/v1/providers/credit_cards`;
        callApi(url, 'PUT', {'credit_cards': [credit_card]})
            .then((json) => {
                setSubmitting(false);
                setButtonDisabled(false);
                handleModalClose();
                updateCreditCardTable();
            })
            .catch((err) => err);
    };

    return (
        <div className="index-page">
            <Formik
                initialValues={data}
                validate={values => {
                    const isNumberRegex = /^-?\d*\.?\d*$/;
                    const errors = {};

                    const required_fields = [
                        'provider_id', 'name', 'network', 'page_url', 'ownership_type', 'primary_type',
                        'tnc_url', 'image_url', 'currency', 'country_code', 'base_earn.type',
                        'base_earn.spend_currency', 'reward.type'
                    ];
                    for (let value of required_fields) {
                        if (!_.get(values, value)) {
                            _.set(errors, value, 'Required');
                        }
                    }

                    if (values.image_url && 
                        !values.image_url.startsWith('https://') &&
                        !(isBase64(values.image_url, { allowMime: true }))) {
                        errors.image_url = 'Invalid URL'
                    }
                    for (let value of [
                            'page_url', 'tnc_url', 'special_earn.tnc_url', 'key_benefit.tnc_url',
                            'promotion.tnc_url', 'redemption.tnc_url', 'reward.tnc_url', 
                            'joining_benefit.application_url', 'joining_benefit.promotion.tnc_url',
                            'joining_benefit.dobin_promotion.tnc_url'
                        ]) {
                            if (_.get(values, value) && !_.get(values, value).startsWith('https://')) {
                                _.set(errors, value, 'Invalid URL');
                            }
                    }
                    values['suggested_places'] && values['suggested_places'].map((suggested_place, index) => {
                        if (suggested_place['image_url'] && 
                            !suggested_place['image_url'].startsWith('https://') &&
                            !(isBase64(suggested_place['image_url'], { allowMime: true }))) {
                            _.set(errors, `suggested_places.${index}.image_url`, 'Invalid URL');
                        }
                    });

                    for (let value of credit_card_number_fields) {
                        if (_.get(values, value) && !(isNumberRegex.test(_.get(values, value)))) {
                            _.set(errors, value, "Invalid Number")
                        }
                    }
                    values['special_earn'] && values['special_earn']['criterias'] && values['special_earn']['criterias'].map((criteria, index) => {
                        if (criteria.capping && criteria.capping.amount && !(isNumberRegex.test(criteria.capping.amount))) {
                            _.set(errors, `special_earn.criterias.${index}.capping.amount`, "Invalid Number")
                        }
                        if (criteria.minimum_spend && criteria.minimum_spend.amount && !(isNumberRegex.test(criteria.minimum_spend.amount))) {
                            _.set(errors, `special_earn.criterias.${index}.minimum_spend.amount`, "Invalid Number")
                        }
                        if (criteria.maximum_spend && criteria.maximum_spend.amount && !(isNumberRegex.test(criteria.maximum_spend.amount))) {
                            _.set(errors, `special_earn.criterias.${index}.maximum_spend.amount`, "Invalid Number")
                        }
                        if (criteria.rate && !(isNumberRegex.test(criteria.rate))) {
                            _.set(errors, `special_earn.criterias.${index}.rate`, "Invalid Number")
                        }
                        if (criteria.spend_block && !(isNumberRegex.test(criteria.spend_block))) {
                            _.set(errors, `special_earn.criterias.${index}.spend_block`, "Invalid Number")
                        }
                    });
                    values['special_earn']['earn_slices'].map((earn_slice, index) => {
                        if (earn_slice.minimum && !(isNumberRegex.test(earn_slice.minimum))) {
                            _.set(errors, `special_earn.earn_slices.${index}.minimum`, "Invalid Number")
                        }
                        if (earn_slice.maximum && !(isNumberRegex.test(earn_slice.maximum))) {
                            _.set(errors, `special_earn.earn_slices.${index}.maximum`, "Invalid Number")
                        }
                    })

                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    setButtonDisabled(true);
                    let credit_card = dataTransformations(values);
                    updateCreditCard(credit_card, setSubmitting);
                }}
            >
                {({ values, isSubmitting, setFieldValue }) => (
                    <Form id="credit-card-edit">
                        <h4>
                            Card Details
                        </h4>
                        <div className="row">
                            <label className="col-md-3">ID:*</label>
                            <Field 
                                className="col-md-8" 
                                readOnly type="text" name="id" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Provider ID:*</label>
                            <Select
                                className="col-md-8"
                                name="provider_id"
                                options={providerIds}
                                defaultValue={{
                                    value: values.provider_id,
                                    label: values.provider_id
                                }}
                                onChange={(selected) => setFieldValue("provider_id", selected.value)} />
                            <ErrorMessage 
                                className="text-danger" 
                                name="provider_id" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Name:*</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="name" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="name" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Network:*</label>
                            <Select
                                className="col-md-8"
                                name="network"
                                options={networks}
                                defaultValue={{
                                    value: values.network,
                                    label: values.network
                                }}
                                onChange={(selected) => setFieldValue("network", selected.value)} />
                            <ErrorMessage 
                                className="text-danger" 
                                name="network" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Page URL:*</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="page_url" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="page_url" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Ownership Type:*</label>
                            <Select
                                className="col-md-8"
                                name="ownership_type"
                                options={ownership_types}
                                defaultValue={{
                                    value: values.ownership_type,
                                    label: values.ownership_type
                                }}
                                onChange={(selected) => setFieldValue("ownership_type", selected.value)} />
                            <ErrorMessage 
                                className="text-danger" 
                                name="ownership_type" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Brand Partner:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="brand_partner" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Primary Type:*</label>
                            <Select
                                className="col-md-8"
                                name="primary_type"
                                options={primary_types}
                                defaultValue={
                                    { 
                                        value: values.primary_type, 
                                        label: (function() {
                                            for (let primary_type of primary_types) {
                                                if (primary_type.value === values.primary_type) {
                                                    return primary_type.label
                                                } 
                                            }
                                            return values.primary_type
                                        }())
                                    }
                                }
                                onChange={(selected) => setFieldValue("primary_type", selected.value)} />
                            <ErrorMessage 
                                className="text-danger" 
                                name="primary_type" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Secondary Types:</label>
                            <Select
                                isMulti
                                className="col-md-8"
                                name="secondary_types"
                                options={secondary_types}
                                defaultValue={values.secondary_types.map(secondary_type => {
                                    return {
                                        label: secondary_type,
                                        value: secondary_type
                                    };
                                })}
                                onChange={(selected) => {
                                    let secondary_types_list = [];
                                    for (let item of selected) {
                                        secondary_types_list.push(item.value);
                                    }
                                    setFieldValue("secondary_types", secondary_types_list);
                                }} />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">TnC URL:*</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="tnc_url" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="tnc_url" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Image URL:*</label>
                            <Field 
                                className="col-md-8"
                                readOnly type="text" name="image_url" />
                            <div>
                                (OR)
                            </div>
                            <Field
                                className="col-md-8"
                                name='image_url'>
                                {({ form, field }) => {
                                    const { setFieldValue } = form
                                    return (
                                        <input
                                            type="file"
                                            accept="image/png, image/jpeg"
                                            className="col-md-8"
                                            onChange={(e) => handleIcon(e, setFieldValue, 'image_url')} />
                                    )
                                }}
                            </Field>
                            <ErrorMessage 
                                className="text-danger" 
                                name="image_url" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Currency:*</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="currency" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="currency" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Country Code:*</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="country_code" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="country_code" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Category Ids:</label>
                            <Select
                                isMulti
                                className="col-md-8"
                                name="category_ids"
                                options={categories}
                                defaultValue={values.category_ids.map(category_id => {
                                    return {
                                        label: category_id,
                                        value: category_id
                                    };
                                })}
                                onChange={(selected) => {
                                    let category_ids = [];
                                    for (let item of selected) {
                                        category_ids.push(item.value);
                                    }
                                    setFieldValue("category_ids", category_ids);
                                }} />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Card Benefits:</label>
                            <FieldArray
                                className="col-md-8"
                                name="card_benefits"
                                render={arrayHelpers => (
                                <div>
                                    {values.card_benefits && values.card_benefits.length > 0 ? (
                                    values.card_benefits.map((card_benefit, index) => (
                                        <div key={index}>
                                        <Field className="col-md-6" name={`card_benefits.${index}`} />
                                        <button
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index)}
                                        >
                                            -
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => arrayHelpers.insert(index, '')}
                                        >
                                            +
                                        </button>
                                        </div>
                                    ))
                                    ) : (
                                    <button type="button" onClick={() => arrayHelpers.push('')}>
                                        Add Card Benefit
                                    </button>
                                    )}
                                </div>
                                )}
                            />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Regex:</label>
                            <FieldArray
                                className="col-md-8"
                                name="regex"
                                render={arrayHelpers => (
                                <div>
                                    {values.regex && values.regex.length > 0 ? (
                                    values.regex.map((r, index) => (
                                        <div key={index}>
                                        <Field className="col-md-6" name={`regex.${index}`} />
                                        <button
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index)}
                                        >
                                            -
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => arrayHelpers.insert(index, '')}
                                        >
                                            +
                                        </button>
                                        </div>
                                    ))
                                    ) : (
                                    <button type="button" onClick={() => arrayHelpers.push('')}>
                                        Add Regex
                                    </button>
                                    )}
                                </div>
                                )}
                            />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Featured Position:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="featured_position" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="featured_position" component="div" />
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Base Earn
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-3">Base Earn:*</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-3">Type:*</label>
                                <Select
                                    className="col-md-8"
                                    name="base_earn.type"
                                    options={base_earn_types}
                                    defaultValue={
                                        values.base_earn && values.base_earn.type ? {
                                            value: values.base_earn.type,
                                            label: values.base_earn.type
                                        } : null
                                    }
                                    onChange={(selected) => setFieldValue("base_earn.type", selected.value)} />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="base_earn.type" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-3">Description:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="base_earn.description" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Short Description:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="base_earn.short_description" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Tool Tip:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="base_earn.tooltip" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-3">Currency:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="base_earn.currency" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-3">Rate:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="base_earn.rate" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="base_earn.rate" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-3">Spend Block:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="base_earn.spend_block" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="base_earn.spend_block" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-3">Spend Currency:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="base_earn.spend_currency" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="base_earn.spend_currency" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-3">Capping:</label>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Amount:</label>
                                    <Field 
                                        className="col-md-8"
                                        type="text" name="base_earn.capping.amount" />
                                    <ErrorMessage 
                                        className="text-danger" 
                                        name="base_earn.capping.amount" component="div" />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Period:</label>
                                    <Select
                                        className="col-md-8"
                                        name="base_earn.capping.period"
                                        options={capping_periods}
                                        defaultValue={
                                            values.base_earn && values.base_earn.capping && values.base_earn.capping.period ? {
                                                value: values.base_earn.capping.period,
                                                label: values.base_earn.capping.period
                                            } : null
                                        }
                                        onChange={(selected) => setFieldValue("base_earn.capping.period", selected.value)} />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Currency:</label>
                                    <Field 
                                        className="col-md-8"
                                        type="text" name="base_earn.capping.currency" />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Description:</label>
                                    <Field 
                                        className="col-md-8"
                                        type="text" name="base_earn.capping.description" />
                                </div>
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-3">Minimum Spend:</label>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Amount:</label>
                                    <Field 
                                        className="col-md-8"
                                        type="text" name="base_earn.minimum_spend.amount" />
                                    <ErrorMessage 
                                        className="text-danger" 
                                        name="base_earn.minimum_spend.amount" component="div" />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Description:</label>
                                    <Field 
                                        className="col-md-8"
                                        type="text" name="base_earn.minimum_spend.description" />
                                </div>
                            </div>
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Special Earn
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-3">Special Earn:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-3">TnC URL:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="special_earn.tnc_url" />
                                <ErrorMessage 
                                        className="text-danger" 
                                        name="special_earn.tnc_url" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-3">Capping:</label>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Amount:</label>
                                    <Field 
                                        className="col-md-8"
                                        type="text" name="special_earn.capping.amount" />
                                    <ErrorMessage 
                                        className="text-danger" 
                                        name="special_earn.capping.amount" component="div" />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Period:</label>
                                    <Select
                                        className="col-md-8"
                                        name="special_earn.capping.period"
                                        options={capping_periods}
                                        defaultValue={
                                            values.special_earn && values.special_earn.capping && values.special_earn.capping.period ? {
                                                value: values.special_earn.capping.period,
                                                label: values.special_earn.capping.period
                                            } : null
                                        }
                                        onChange={(selected) => setFieldValue("special_earn.capping.period", selected.value)} />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Currency:</label>
                                    <Field 
                                        className="col-md-8"
                                        type="text" name="special_earn.capping.currency" />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Description:</label>
                                    <Field 
                                        className="col-md-8"
                                        type="text" name="special_earn.capping.description" />
                                </div>
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-3">Minimum Spend:</label>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Amount:</label>
                                    <Field 
                                        className="col-md-8"
                                        type="text" name="special_earn.minimum_spend.amount" />
                                    <ErrorMessage 
                                        className="text-danger" 
                                        name="special_earn.minimum_spend.amount" component="div" />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Description:</label>
                                    <Field 
                                        className="col-md-8"
                                        type="text" name="special_earn.minimum_spend.description" />
                                </div>
                            </div>
                            <div className="row mt-40">
                                <label className="col-md-2">Earn Slices:</label>
                                <FieldArray
                                    className="col-md-8"
                                    name="special_earn.earn_slices"
                                    render={arrayHelpers => (
                                    <div>
                                        {values.special_earn && values.special_earn.earn_slices && values.special_earn.earn_slices.length > 0 ? (
                                        values.special_earn.earn_slices.map((slice, index) => (
                                            <div key={index}>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Minimum:</label>
                                                    <Field 
                                                        className="col-md-8"
                                                        type="text" name={`special_earn.earn_slices.${index}.minimum`} />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Maximum:</label>
                                                    <Field 
                                                        className="col-md-8"
                                                        type="text" name={`special_earn.earn_slices.${index}.maximum`} />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Title:</label>
                                                    <Field 
                                                        className="col-md-8"
                                                        type="text" name={`special_earn.earn_slices.${index}.title`} />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Description:</label>
                                                    <Field 
                                                        className="col-md-8"
                                                        type="text" name={`special_earn.earn_slices.${index}.description`} />
                                                </div>
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    -
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.push(_.cloneDeep(earn_slice))}
                                                >
                                                    +
                                                </button>
                                            </div>
                                        ))
                                        ) : (
                                        <button type="button" onClick={() => arrayHelpers.push(_.cloneDeep(earn_slice))}>
                                            Add Special Earn Slice
                                        </button>
                                        )}
                                    </div>
                                    )}
                                />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Earn Slices Tool Tips:</label>
                                <FieldArray
                                    className="col-md-8"
                                    name={`special_earn.earn_slices_tooltips`}
                                    render={subArrayHelpers => (
                                    <div>
                                        { values.special_earn && values.special_earn.earn_slices_tooltips && values.special_earn.earn_slices_tooltips.length > 0 ? (
                                        values.special_earn.earn_slices_tooltips.map((earn_slice_tooltip, index) => (
                                            <div key={'earn_slices_tooltips' + index}>
                                            <Field className="col-md-6" name={`special_earn.earn_slices_tooltips.${index}`} />
                                            <button
                                                type="button"
                                                onClick={() => subArrayHelpers.remove(index)}
                                            >
                                                -
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => subArrayHelpers.insert(index, '')}
                                            >
                                                +
                                            </button>
                                            </div>
                                        ))
                                        ) : (
                                        <button type="button" onClick={() => subArrayHelpers.push('')}>
                                            Add Special Earn Slices Tool Tip
                                        </button>
                                        )}
                                    </div>
                                    )}
                                />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-3">Criterias:</label>
                                <FieldArray
                                    className="col-md-8"
                                    name="special_earn.criterias"
                                    render={arrayHelpers => (
                                    <div>
                                        { values.special_earn && values.special_earn.criterias && values.special_earn.criterias.length > 0 ? (
                                        values.special_earn.criterias.map((criteria, index) => (
                                            <div key={index}>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-3">Type:</label>
                                                    <Field 
                                                        className="col-md-8"
                                                        type="text" name={`special_earn.criterias.${index}.type`} />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-3">Description:</label>
                                                    <Field 
                                                        className="col-md-8"
                                                        type="text" name={`special_earn.criterias.${index}.description`} />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-3">Short Description:</label>
                                                    <Field 
                                                        className="col-md-8"
                                                        type="text" name={`special_earn.criterias.${index}.short_description`} />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Tool Tip:</label>
                                                    <Field 
                                                        className="col-md-8"
                                                        type="text" name={`special_earn.criterias.${index}.tooltip`} />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Currency:</label>
                                                    <Field 
                                                        className="col-md-8"
                                                        type="text" name={`special_earn.criterias.${index}.currency`} />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Rate:</label>
                                                    <Field 
                                                        className="col-md-8"
                                                        type="text" name={`special_earn.criterias.${index}.rate`} />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Spend Block:</label>
                                                    <Field 
                                                        className="col-md-8"
                                                        type="text" name={`special_earn.criterias.${index}.spend_block`} />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-3">Capping:</label>
                                                    <div className="row mt-40 px-5">
                                                        <label className="col-md-3">Amount:</label>
                                                        <Field 
                                                            className="col-md-8"
                                                            type="text" name={`special_earn.criterias.${index}.capping.amount`} />
                                                        <ErrorMessage 
                                                            className="text-danger" 
                                                            name={`special_earn.criterias.${index}.capping.amount`} component="div" />
                                                    </div>
                                                    <div className="row mt-40 px-5">
                                                        <label className="col-md-3">Period:</label>
                                                        <Select
                                                            className="col-md-8"
                                                            name={`special_earn.criterias.${index}.capping.period`}
                                                            options={capping_periods}
                                                            defaultValue={
                                                                (values.special_earn && values.special_earn.criterias && values.special_earn.criterias[index].capping &&
                                                                    values.special_earn.criterias[index].capping.period) ? {
                                                                        value: values.special_earn.criterias[index].capping.period,
                                                                        label: values.special_earn.criterias[index].capping.period
                                                                } : null
                                                            }
                                                            onChange={(selected) => setFieldValue(`special_earn.criterias.${index}.capping.period`, selected.value)} />
                                                    </div>
                                                    <div className="row mt-40 px-5">
                                                        <label className="col-md-3">Currency:</label>
                                                        <Field 
                                                            className="col-md-8"
                                                            type="text" name={`special_earn.criterias.${index}.capping.currency`} />
                                                    </div>
                                                    <div className="row mt-40 px-5">
                                                        <label className="col-md-3">Description:</label>
                                                        <Field 
                                                            className="col-md-8"
                                                            type="text" name={`special_earn.criterias.${index}.capping.description`} />
                                                    </div>
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-3">Minimum Spend:</label>
                                                    <div className="row mt-40 px-5">
                                                        <label className="col-md-3">Amount:</label>
                                                        <Field 
                                                            className="col-md-8"
                                                            type="text" name={`special_earn.criterias.${index}.minimum_spend.amount`} />
                                                        <ErrorMessage 
                                                            className="text-danger" 
                                                            name={`special_earn.criterias.${index}.minimum_spend.amount`} component="div" />
                                                    </div>
                                                    <div className="row mt-40 px-5">
                                                        <label className="col-md-3">Description:</label>
                                                        <Field 
                                                            className="col-md-8"
                                                            type="text" name={`special_earn.criterias.${index}.minimum_spend.description`} />
                                                    </div>
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Maximum Spend:</label>
                                                    <div className="row mt-40 px-5">
                                                        <label className="col-md-2">Amount:</label>
                                                        <Field 
                                                            className="col-md-8"
                                                            type="text" name={`special_earn.criterias.${index}.maximum_spend.amount`} />
                                                        <ErrorMessage 
                                                            className="text-danger" 
                                                            name={`special_earn.criterias.${index}.maximum_spend.amount`} component="div" />
                                                    </div>
                                                    <div className="row mt-40 px-5">
                                                        <label className="col-md-2">Description:</label>
                                                        <Field 
                                                            className="col-md-8"
                                                            type="text" name={`special_earn.criterias.${index}.maximum_spend.description`} />
                                                    </div>
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">MCCs:</label>
                                                    <FieldArray
                                                        className="col-md-8"
                                                        name={`special_earn.criterias.${index}.mccs`}
                                                        render={subArrayHelpers => (
                                                        <div>
                                                            {values.special_earn.criterias[index].mccs && values.special_earn.criterias[index].mccs.length > 0 ? (
                                                            values.special_earn.criterias[index].mccs.map((r, sub_index) => (
                                                                <div key={index + 'mccs' + sub_index}>
                                                                <Field className="col-md-6" name={`special_earn.criterias.${index}.mccs.${sub_index}`} />
                                                                <button
                                                                    type="button"
                                                                    onClick={() => subArrayHelpers.remove(sub_index)}
                                                                >
                                                                    -
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => subArrayHelpers.insert(sub_index, '')}
                                                                >
                                                                    +
                                                                </button>
                                                                </div>
                                                            ))
                                                            ) : (
                                                            <button type="button" onClick={() => subArrayHelpers.push('')}>
                                                                Add Special Earn Criteria MCC
                                                            </button>
                                                            )}
                                                        </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-3">Merchant Names:</label>
                                                    <FieldArray
                                                        className="col-md-8"
                                                        name={`special_earn.criterias.${index}.merchant_names`}
                                                        render={subArrayHelpers => (
                                                        <div>
                                                            {values.special_earn.criterias[index].merchant_names && values.special_earn.criterias[index].merchant_names.length > 0 ? (
                                                            values.special_earn.criterias[index].merchant_names.map((r, sub_index) => (
                                                                <div key={index + 'merchant_names' + sub_index}>
                                                                <Field className="col-md-6" name={`special_earn.criterias.${index}.merchant_names.${sub_index}`} />
                                                                <button
                                                                    type="button"
                                                                    onClick={() => subArrayHelpers.remove(sub_index)}
                                                                >
                                                                    -
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => subArrayHelpers.insert(sub_index, '')}
                                                                >
                                                                    +
                                                                </button>
                                                                </div>
                                                            ))
                                                            ) : (
                                                            <button type="button" onClick={() => subArrayHelpers.push('')}>
                                                                Add Special Earn Criteria Merchant Name
                                                            </button>
                                                            )}
                                                        </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-3">Categories:</label>
                                                    <FieldArray
                                                        className="col-md-8"
                                                        name={`special_earn.criterias.${index}.categories`}
                                                        render={subArrayHelpers => (
                                                        <div>
                                                            {values.special_earn.criterias[index].categories && values.special_earn.criterias[index].categories.length > 0 ? (
                                                            values.special_earn.criterias[index].categories.map((r, sub_index) => (
                                                                <div key={index + 'categories' + sub_index}>
                                                                <Field className="col-md-6" name={`special_earn.criterias.${index}.categories.${sub_index}`} />
                                                                <button
                                                                    type="button"
                                                                    onClick={() => subArrayHelpers.remove(sub_index)}
                                                                >
                                                                    -
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => subArrayHelpers.insert(sub_index, '')}
                                                                >
                                                                    +
                                                                </button>
                                                                </div>
                                                            ))
                                                            ) : (
                                                            <button type="button" onClick={() => subArrayHelpers.push('')}>
                                                                Add Special Earn Criteria Category
                                                            </button>
                                                            )}
                                                        </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Modes:</label>
                                                    <FieldArray
                                                        className="col-md-8"
                                                        name={`special_earn.criterias.${index}.modes`}
                                                        render={subArrayHelpers => (
                                                        <div>
                                                            {values.special_earn.criterias[index].modes && values.special_earn.criterias[index].modes.length > 0 ? (
                                                            values.special_earn.criterias[index].modes.map((r, sub_index) => (
                                                                <div key={index + 'modes' + sub_index}>
                                                                <Field className="col-md-6" name={`special_earn.criterias.${index}.modes.${sub_index}`} />
                                                                <button
                                                                    type="button"
                                                                    onClick={() => subArrayHelpers.remove(sub_index)}
                                                                >
                                                                    -
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => subArrayHelpers.insert(sub_index, '')}
                                                                >
                                                                    +
                                                                </button>
                                                                </div>
                                                            ))
                                                            ) : (
                                                            <button type="button" onClick={() => subArrayHelpers.push('')}>
                                                                Add Special Earn Criteria Modes
                                                            </button>
                                                            )}
                                                        </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Exclusion MCCs:</label>
                                                    <FieldArray
                                                        className="col-md-8"
                                                        name={`special_earn.criterias.${index}.exclusion_mccs`}
                                                        render={subArrayHelpers => (
                                                        <div>
                                                            {values.special_earn.criterias[index].exclusion_mccs && values.special_earn.criterias[index].exclusion_mccs.length > 0 ? (
                                                            values.special_earn.criterias[index].exclusion_mccs.map((r, sub_index) => (
                                                                <div key={index + 'exclusion_mccs' + sub_index}>
                                                                <Field className="col-md-6" name={`special_earn.criterias.${index}.exclusion_mccs.${sub_index}`} />
                                                                <button
                                                                    type="button"
                                                                    onClick={() => subArrayHelpers.remove(sub_index)}
                                                                >
                                                                    -
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => subArrayHelpers.insert(sub_index, '')}
                                                                >
                                                                    +
                                                                </button>
                                                                </div>
                                                            ))
                                                            ) : (
                                                            <button type="button" onClick={() => subArrayHelpers.push('')}>
                                                                Add Special Earn Criteria Exclusion MCC
                                                            </button>
                                                            )}
                                                        </div>
                                                        )}
                                                    />
                                                </div>
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    -
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.insert(index, _.cloneDeep(special_earn_criteria))}
                                                >
                                                    +
                                                </button>
                                            </div>
                                        ))
                                        ) : (
                                        <button type="button" onClick={() => arrayHelpers.push(_.cloneDeep(special_earn_criteria))}>
                                            Add Special Earn Criteria
                                        </button>
                                        )}
                                    </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Key Benefits
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-3">Key Benefit:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-3">TnC URL:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="key_benefit.tnc_url" />
                                <ErrorMessage 
                                        className="text-danger" 
                                        name="key_benefit.tnc_url" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-3">Description:</label>
                                <FieldArray
                                    className="col-md-8"
                                    name="key_benefit.description"
                                    render={arrayHelpers => (
                                    <div>
                                        {values.key_benefit && values.key_benefit.description && values.key_benefit.description.length > 0 ? (
                                        values.key_benefit.description.map((description, index) => (
                                            <div key={index}>
                                            <Field className="col-md-6" name={`key_benefit.description.${index}`} />
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                -
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.insert(index, '')}
                                            >
                                                +
                                            </button>
                                            </div>
                                        ))
                                        ) : (
                                        <button type="button" onClick={() => arrayHelpers.push('')}>
                                            Add Key Benefit Description
                                        </button>
                                        )}
                                    </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Promotion
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-3">Promotion:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-3">TnC URL:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="promotion.tnc_url" />
                                <ErrorMessage 
                                        className="text-danger" 
                                        name="promotion.tnc_url" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-3">Description:</label>
                                <FieldArray
                                    className="col-md-8"
                                    name="promotion.description"
                                    render={arrayHelpers => (
                                    <div>
                                        { values.promotion && values.promotion.description && values.promotion.description.length > 0 ? (
                                        values.promotion.description.map((description, index) => (
                                            <div key={index}>
                                            <Field className="col-md-6" name={`promotion.description.${index}`} />
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                -
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.insert(index, '')}
                                            >
                                                +
                                            </button>
                                            </div>
                                        ))
                                        ) : (
                                        <button type="button" onClick={() => arrayHelpers.push('')}>
                                            Add Promotion Description
                                        </button>
                                        )}
                                    </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Suggested Places
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-3">Suggested Places:</label>
                            <FieldArray
                                className="col-md-8"
                                name="suggested_places"
                                render={arrayHelpers => (
                                <div>
                                    {values.suggested_places && values.suggested_places.length > 0 ? (
                                    values.suggested_places.map((sp, index) => (
                                        <div key={index}>
                                            <div className="row mt-40 px-5">
                                                <label className="col-md-3">Name:</label>
                                                <Field 
                                                    className="col-md-8"
                                                    type="text" name={`suggested_places.${index}.name`} />
                                            </div>
                                            <div className="row mt-40 px-5">
                                                <label className="col-md-3">Description:</label>
                                                <Field 
                                                    className="col-md-8"
                                                    type="text" name={`suggested_places.${index}.description`} />
                                            </div>
                                            <div className="row mt-40 px-5">
                                                <label className="col-md-3">Image URL:</label>
                                                <Field 
                                                    className="col-md-8"
                                                    readOnly type="text" name={`suggested_places.${index}.image_url`} />
                                                <div>
                                                    (OR)
                                                </div>
                                                <Field
                                                    className="col-md-8"
                                                    name={`suggested_places.${index}.image_url`}>
                                                    {({ form, field }) => {
                                                        const { setFieldValue } = form
                                                        return (
                                                            <input
                                                                type="file"
                                                                accept="image/png, image/jpeg"
                                                                className="col-md-8"
                                                                onChange={(e) => handleIcon(e, setFieldValue, `suggested_places.${index}.image_url`)} />
                                                        )
                                                    }}
                                                </Field>
                                                <ErrorMessage 
                                                    className="text-danger" 
                                                    name={`suggested_places.${index}.image_url`} component="div" />
                                            </div>
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                -
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.push(_.cloneDeep(suggested_place))}
                                            >
                                                +
                                            </button>
                                        </div>
                                    ))
                                    ) : (
                                    <button type="button" onClick={() => arrayHelpers.push(_.cloneDeep(suggested_place))}>
                                        Add Suggested Place
                                    </button>
                                    )}
                                </div>
                                )}
                            />
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Redemption
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-3">Redemption:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-3">TnC URL:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="redemption.tnc_url" />
                                <ErrorMessage 
                                        className="text-danger" 
                                        name="redemption.tnc_url" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-3">Options:</label>
                                <FieldArray
                                    className="col-md-8"
                                    name="redemption.options"
                                    render={arrayHelpers => (
                                    <div>
                                        { values.redemption && values.redemption.options && values.redemption.options.length > 0 ? (
                                        values.redemption.options.map((option, index) => (
                                            <div key={index}>
                                            <Field className="col-md-6" name={`redemption.options.${index}`} />
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                -
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.insert(index, '')}
                                            >
                                                +
                                            </button>
                                            </div>
                                        ))
                                        ) : (
                                        <button type="button" onClick={() => arrayHelpers.push('')}>
                                            Add Redemption Option
                                        </button>
                                        )}
                                    </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Reward
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-3">Reward:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-3">TnC URL:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="reward.tnc_url" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="reward.tnc_url" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-3">Type:*</label>
                                <Select
                                    className="col-md-8"
                                    name="reward.type"
                                    options={reward_types}
                                    defaultValue={
                                        values.reward && values.reward.type ? {
                                            value: values.reward.type,
                                            label: values.reward.type
                                        } : null
                                    }
                                    onChange={(selected) => setFieldValue("reward.type", selected.value)} />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="reward.type" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-3">Strategies:</label>
                                <FieldArray
                                    className="col-md-8"
                                    name="reward.strategies"
                                    render={arrayHelpers => (
                                    <div>
                                        { values.reward && values.reward.strategies && values.reward.strategies.length > 0 ? (
                                        values.reward.strategies.map((strategy, index) => (
                                            <div key={index}>
                                            <Field className="col-md-6" name={`reward.strategies.${index}`} />
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                -
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.insert(index, '')}
                                            >
                                                +
                                            </button>
                                            </div>
                                        ))
                                        ) : (
                                        <button type="button" onClick={() => arrayHelpers.push('')}>
                                            Add Reward Strategy
                                        </button>
                                        )}
                                    </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Lounge
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Lounge:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Description:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name={`lounge.description`} />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Access:</label>
                                <Switch
                                    className="col-md-8"
                                    checked={values.lounge.access}
                                    onChange={() => setFieldValue("lounge.access", !values.lounge.access)} />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Max Visits:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name={`lounge.max_visits`} />
                            </div>
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Exclusion
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Exclusion:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">MCCs:</label>
                                <FieldArray
                                    className="col-md-8"
                                    name="exclusion.mccs"
                                    render={arrayHelpers => (
                                    <div>
                                        {values.exclusion.mccs && values.exclusion.mccs.length > 0 ? (
                                        values.exclusion.mccs.map((mcc, index) => (
                                            <div key={index}>
                                            <Field className="col-md-6" name={`exclusion.mccs.${index}`} />
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                -
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.insert(index, '')}
                                            >
                                                +
                                            </button>
                                            </div>
                                        ))
                                        ) : (
                                        <button type="button" onClick={() => arrayHelpers.push('')}>
                                            Add Exclusion MCC
                                        </button>
                                        )}
                                    </div>
                                    )}
                                />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Categories:</label>
                                <FieldArray
                                    className="col-md-8"
                                    name="exclusion.categories"
                                    render={arrayHelpers => (
                                    <div>
                                        {values.exclusion.categories && values.exclusion.categories.length > 0 ? (
                                        values.exclusion.categories.map((category, index) => (
                                            <div key={index}>
                                            <Field className="col-md-6" name={`exclusion.categories.${index}`} />
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                -
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.insert(index, '')}
                                            >
                                                +
                                            </button>
                                            </div>
                                        ))
                                        ) : (
                                        <button type="button" onClick={() => arrayHelpers.push('')}>
                                            Add Exclusion Category
                                        </button>
                                        )}
                                    </div>
                                    )}
                                />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Expressions:</label>
                                <FieldArray
                                    className="col-md-8"
                                    name="exclusion.expressions"
                                    render={arrayHelpers => (
                                    <div>
                                        {values.exclusion.expressions && values.exclusion.expressions.length > 0 ? (
                                        values.exclusion.expressions.map((expression, index) => (
                                            <div key={index}>
                                            <Field className="col-md-6" name={`exclusion.expressions.${index}`} />
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                -
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.insert(index, '')}
                                            >
                                                +
                                            </button>
                                            </div>
                                        ))
                                        ) : (
                                        <button type="button" onClick={() => arrayHelpers.push('')}>
                                            Add Exclusion Expression
                                        </button>
                                        )}
                                    </div>
                                    )}
                                />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Card Statements:</label>
                                <FieldArray
                                    className="col-md-8"
                                    name="exclusion.card_statements"
                                    render={arrayHelpers => (
                                    <div>
                                        {values.exclusion.card_statements && values.exclusion.card_statements.length > 0 ? (
                                        values.exclusion.card_statements.map((card_statement, index) => (
                                            <div key={index}>
                                            <Field className="col-md-6" name={`exclusion.card_statements.${index}`} />
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                -
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.insert(index, '')}
                                            >
                                                +
                                            </button>
                                            </div>
                                        ))
                                        ) : (
                                        <button type="button" onClick={() => arrayHelpers.push('')}>
                                            Add Exclusion Card Statement
                                        </button>
                                        )}
                                    </div>
                                    )}
                                />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Merchant Names:</label>
                                <FieldArray
                                    className="col-md-8"
                                    name="exclusion.merchant_names"
                                    render={arrayHelpers => (
                                    <div>
                                        {values.exclusion.merchant_names && values.exclusion.merchant_names.length > 0 ? (
                                        values.exclusion.merchant_names.map((merchant_name, index) => (
                                            <div key={index}>
                                            <Field className="col-md-6" name={`exclusion.merchant_names.${index}`} />
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                -
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.insert(index, '')}
                                            >
                                                +
                                            </button>
                                            </div>
                                        ))
                                        ) : (
                                        <button type="button" onClick={() => arrayHelpers.push('')}>
                                            Add Exclusion Merchant Name
                                        </button>
                                        )}
                                    </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Discontinued
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Discontinued:</label>
                            <Switch
                                className="col-md-8"
                                checked={values.discontinued}
                                onChange={() => setFieldValue("discontinued", !values.discontinued)} />
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Invite Only
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Invite Only:</label>
                            <Switch
                                className="col-md-8"
                                checked={values.invite_only}
                                onChange={() => setFieldValue("invite_only", !values.invite_only)} />
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Fees/Rates
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Annual Fee:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Amount:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name={`annual_fee.amount`} />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Description:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name={`annual_fee.description`} />
                            </div>
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Supplementary Card Fee:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Amount:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name={`supplementary_card_fee.amount`} />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Description:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name={`supplementary_card_fee.description`} />
                            </div>
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Purchase Rate:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Percent:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name={`purchase_rate.percent`} />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Description:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name={`purchase_rate.description`} />
                            </div>
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Cash Rate:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Percent:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name={`cash_rate.percent`} />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Description:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name={`cash_rate.description`} />
                            </div>
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Cash Fee Rate:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Percent:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name={`cash_fee_rate.percent`} />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Description:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name={`cash_fee_rate.description`} />
                            </div>
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Free period:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="free_period" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Forex FCY Fee:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="forex_fcy_fee" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Forex DCC Fee:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="forex_dcc_fee" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Overlimit Fee:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="overlimit_fee" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Repayment Rate:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Percent:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name={`repayment_rate.percent`} />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Description:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name={`repayment_rate.description`} />
                            </div>
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Late Payment Fee:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="late_payment_fee" />
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Eligibility
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Eligibility:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Citizens:</label>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Age:</label>
                                    <Field 
                                        className="col-md-8"
                                        type="text" name={`eligibility.citizens.age`} />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Minimum Income:</label>
                                    <div className="row mt-40 px-5">
                                        <label className="col-md-2">Amount:</label>
                                        <Field 
                                            className="col-md-8"
                                            type="text" name={`eligibility.citizens.minimum_income.amount`} />
                                    </div>
                                    <div className="row mt-40 px-5">
                                        <label className="col-md-2">Description:</label>
                                        <Field 
                                            className="col-md-8"
                                            type="text" name={`eligibility.citizens.minimum_income.description`} />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Foreigners:</label>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Age:</label>
                                    <Field 
                                        className="col-md-8"
                                        type="text" name={`eligibility.foreigners.age`} />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Minimum Income:</label>
                                    <div className="row mt-40 px-5">
                                        <label className="col-md-2">Amount:</label>
                                        <Field 
                                            className="col-md-8"
                                            type="text" name={`eligibility.foreigners.minimum_income.amount`} />
                                    </div>
                                    <div className="row mt-40 px-5">
                                        <label className="col-md-2">Description:</label>
                                        <Field 
                                            className="col-md-8"
                                            type="text" name={`eligibility.foreigners.minimum_income.description`} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Features
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Star Features:</label>
                            <FieldArray
                                className="col-md-8"
                                name="star_features"
                                render={arrayHelpers => (
                                <div>
                                    {values.star_features && values.star_features.length > 0 ? (
                                    values.star_features.map((star_feature, index) => (
                                        <div key={index}>
                                        <Field className="col-md-6" name={`star_features.${index}`} />
                                        <button
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index)}
                                        >
                                            -
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => arrayHelpers.insert(index, '')}
                                        >
                                            +
                                        </button>
                                        </div>
                                    ))
                                    ) : (
                                    <button type="button" onClick={() => arrayHelpers.push('')}>
                                        Add Star Feature
                                    </button>
                                    )}
                                </div>
                                )}
                            />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Limit Features:</label>
                            <FieldArray
                                className="col-md-8"
                                name="limit_features"
                                render={arrayHelpers => (
                                <div>
                                    {values.limit_features && values.limit_features.length > 0 ? (
                                    values.limit_features.map((limit_feature, index) => (
                                        <div key={index}>
                                        <Field className="col-md-6" name={`limit_features.${index}`} />
                                        <button
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index)}
                                        >
                                            -
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => arrayHelpers.insert(index, '')}
                                        >
                                            +
                                        </button>
                                        </div>
                                    ))
                                    ) : (
                                    <button type="button" onClick={() => arrayHelpers.push('')}>
                                        Add Limit Feature
                                    </button>
                                    )}
                                </div>
                                )}
                            />
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Joining Benefit
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Joining Benefit:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Partner:</label>
                                <Select
                                    className="col-md-8"
                                    name="joining_benefit.partner"
                                    options={partnerNames}
                                    defaultValue={{
                                        value: values.joining_benefit.partner,
                                        label: values.joining_benefit.partner
                                    }}
                                    onChange={(selected) => setFieldValue("joining_benefit.partner", selected.value)} />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Fulfilments:</label>
                                <FieldArray
                                    className="col-md-8"
                                    name="joining_benefit.fulfilments"
                                    render={arrayHelpers => (
                                    <div>
                                        {values.joining_benefit && values.joining_benefit.fulfilments && values.joining_benefit.fulfilments.length > 0 ? (
                                        values.joining_benefit.fulfilments.map((fulfilment, index) => (
                                            <div key={index}>
                                            <Field className="col-md-6" name={`joining_benefit.fulfilments.${index}`} />
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                -
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.insert(index, '')}
                                            >
                                                +
                                            </button>
                                            </div>
                                        ))
                                        ) : (
                                        <button type="button" onClick={() => arrayHelpers.push('')}>
                                            Add Joining Benefit Fulfilment
                                        </button>
                                        )}
                                    </div>
                                    )}
                                />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Application URL:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name={`joining_benefit.application_url`} />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="joining_benefit.application_url" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Product:</label>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Description:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="joining_benefit.product.description"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.joining_benefit && values.joining_benefit.product && 
                                            values.joining_benefit.product.description && values.joining_benefit.product.description.length > 0 ? (
                                            values.joining_benefit.product.description.map((description, index) => (
                                                <div key={index}>
                                                <Field className="col-md-6" name={`joining_benefit.product.description.${index}`} />
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    -
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.insert(index, '')}
                                                >
                                                    +
                                                </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add Joining Benefit Product Description
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Criterias:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="joining_benefit.product.criterias"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.joining_benefit && values.joining_benefit.product && 
                                            values.joining_benefit.product.criterias && values.joining_benefit.product.criterias.length > 0 ? (
                                            values.joining_benefit.product.criterias.map((criteria, index) => (
                                                <div key={index}>
                                                <Field className="col-md-6" name={`joining_benefit.product.criterias.${index}`} />
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    -
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.insert(index, '')}
                                                >
                                                    +
                                                </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add Joining Benefit Product Criteria
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Features:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="joining_benefit.product.features"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.joining_benefit && values.joining_benefit.product && 
                                            values.joining_benefit.product.features && values.joining_benefit.product.features.length > 0 ? (
                                            values.joining_benefit.product.features.map((feature, index) => (
                                                <div key={index}>
                                                <Field className="col-md-6" name={`joining_benefit.product.features.${index}`} />
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    -
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.insert(index, '')}
                                                >
                                                    +
                                                </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add Joining Benefit Product Feature
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Promotion:</label>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Name:</label>
                                    <Field 
                                        className="col-md-8"
                                        type="text" name={`joining_benefit.promotion.name`} />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Description:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="joining_benefit.promotion.description"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.joining_benefit && values.joining_benefit.promotion && values.joining_benefit.promotion.description.length > 0 ? (
                                            values.joining_benefit.promotion.description.map((description, index) => (
                                                <div key={index}>
                                                <Field className="col-md-6" name={`joining_benefit.promotion.description.${index}`} />
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    -
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.insert(index, '')}
                                                >
                                                    +
                                                </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add Joining Benefit Promotion Description
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Expiry Date: (SGT = UTC - 1)</label>
                                    <Field 
                                        className="col-md-8"
                                        type="date" name="joining_benefit.promotion.expiry_datetime" />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Criterias:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="joining_benefit.promotion.criterias"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.joining_benefit && values.joining_benefit.promotion.criterias && 
                                            values.joining_benefit.promotion.criterias && values.joining_benefit.promotion.criterias.length > 0 ? (
                                            values.joining_benefit.promotion.criterias.map((criteria, index) => (
                                                <div key={index}>
                                                <Field className="col-md-6" name={`joining_benefit.promotion.criterias.${index}`} />
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    -
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.insert(index, '')}
                                                >
                                                    +
                                                </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add Joining Benefit Promotion Criteria
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">TnC URL:</label>
                                    <Field 
                                        className="col-md-8"
                                        type="text" name={`joining_benefit.promotion.tnc_url`} />
                                    <ErrorMessage 
                                        className="text-danger" 
                                        name="joining_benefit.promotion.tnc_url" component="div" />
                                </div>
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Dobin Promotion:</label>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Fulfilments:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="joining_benefit.dobin_promotion.fulfilments"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.joining_benefit && values.joining_benefit.dobin_promotion &&
                                            values.joining_benefit.dobin_promotion.fulfilments && 
                                            values.joining_benefit.dobin_promotion.fulfilments.length > 0 ? (
                                            values.joining_benefit.dobin_promotion.fulfilments.map((fulfilment, index) => (
                                                <div key={index}>
                                                <Field className="col-md-6" name={`joining_benefit.dobin_promotion.fulfilments.${index}`} />
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    -
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.insert(index, '')}
                                                >
                                                    +
                                                </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add Joining Benefit Dobin Promotion Fulfilment
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Description:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="joining_benefit.dobin_promotion.description"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.joining_benefit && values.joining_benefit.dobin_promotion && 
                                            values.joining_benefit.dobin_promotion.description &&
                                            values.joining_benefit.dobin_promotion.description.length > 0 ? (
                                            values.joining_benefit.dobin_promotion.description.map((description, index) => (
                                                <div key={index}>
                                                <Field className="col-md-6" name={`joining_benefit.dobin_promotion.description.${index}`} />
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    -
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.insert(index, '')}
                                                >
                                                    +
                                                </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add Joining Benefit Dobin Promotion Description
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Expiry Date: (SGT = UTC - 1)</label>
                                    <Field 
                                        className="col-md-8"
                                        type="date" name="joining_benefit.dobin_promotion.expiry_datetime" />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Criterias:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="joining_benefit.dobin_promotion.criterias"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.joining_benefit && values.joining_benefit.dobin_promotion &&
                                            values.joining_benefit.dobin_promotion.criterias && 
                                            values.joining_benefit.dobin_promotion.criterias.length > 0 ? (
                                            values.joining_benefit.dobin_promotion.criterias.map((criteria, index) => (
                                                <div key={index}>
                                                <Field className="col-md-6" name={`joining_benefit.dobin_promotion.criterias.${index}`} />
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    -
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.insert(index, '')}
                                                >
                                                    +
                                                </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add Joining Benefit Dobin Promotion Criteria
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">TnC URL:</label>
                                    <Field 
                                        className="col-md-8"
                                        type="text" name={`joining_benefit.dobin_promotion.tnc_url`} />
                                    <ErrorMessage 
                                        className="text-danger" 
                                        name="joining_benefit.dobin_promotion.tnc_url" component="div" />
                                </div>
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Bank Promotions:</label>
                                <FieldArray
                                    className="col-md-8"
                                    name="joining_benefit.bank_promotions"
                                    render={arrayHelpers => (
                                    <div>
                                        {values.joining_benefit && values.joining_benefit.bank_promotions && values.joining_benefit.bank_promotions.length > 0 ? (
                                        values.joining_benefit.bank_promotions.map((bank_promotion, index) => (
                                            <div key={index}>
                                            <Field className="col-md-6" name={`joining_benefit.bank_promotions.${index}`} />
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                -
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.insert(index, '')}
                                            >
                                                +
                                            </button>
                                            </div>
                                        ))
                                        ) : (
                                        <button type="button" onClick={() => arrayHelpers.push('')}>
                                            Add Joining Benefit Bank Promotion
                                        </button>
                                        )}
                                    </div>
                                    )}
                                />
                            </div>
                        </div>
                        {/* <div className="index-container">
                            <button 
                                className="index-button"
                                type="submit" disabled={isSubmitting}>
                                    Save
                            </button>
                        </div> */}
                    </Form>
                )}
            </Formik>
        </div>
    );
};


export default CreditCardAction;